import React, { useEffect } from "react";

import AdminLayout from "./components/AdminLayout/Admin";
import ForgotPassword from "./components/ForgotPassword";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
// import 'react-select/dist/react-select.css';
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import axios from "axios";
import routes from "./components/AdminLayout/routes";
//add the following lines to any file where you import axios
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

function App() {
  useEffect(() => {
    if (window.dataLayer) {
      // get user_default_email from local storage
      const user_default_email = localStorage.getItem("user_default_email");
      if (user_default_email){
      window.dataLayer.push({
        event: "start_pw",
        pw_user_email: user_default_email,
      });
    }
    else{
      window.dataLayer.push({
        event: "start_pw",
      });

    }
    }
    // Remove any existing chat scripts
    const existingScript = document.getElementById("tawkToScript");
    if (existingScript) {
      existingScript.remove();
    }



    const charlaScript = document.createElement("script");
    charlaScript.type = "text/javascript";
    charlaScript.charset = "utf-8";
    charlaScript.innerHTML = `window.addEventListener('load', () => { const widgetElement = document.createElement('charla-widget'); widgetElement.setAttribute("p", "7b19dc3c-f7c8-45a6-bd67-a0be0cd7c87d"); document.body.appendChild(widgetElement) ; const widgetCode = document.createElement('script'); widgetCode.src = 'https://app.getcharla.com/widget/widget.js'; document.body.appendChild(widgetCode); })`;

    // Insert the script into the document
    const scriptParentNode = document.getElementsByTagName("script")[0];
    scriptParentNode.parentNode.insertBefore(charlaScript, scriptParentNode);

    // Clean up the script when the component unmounts
    return () => {
      scriptParentNode.parentNode.removeChild(charlaScript);
    };
  }, []);

  const navigate = useNavigate();
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/user" && prop.component) {
        const Element = prop.component;
        if (
          localStorage.getItem("access_token") ||
          prop.path === "/user/change-password"
        ) {
          return (
            <Route
              path={prop.path}
              element={<Element />}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };

  return (
    <div className="App">
      <Routes>
        <Route
          path="/login"
          element={<Login />}
        />
        <Route
          path="/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          path="/reset-password"
          element={<ResetPassword />}
        />

        <Route
          path="/user"
          element={<AdminLayout />}>
          {getRoutes(routes)}
        </Route>

        <Route
          path="/*"
          element={<Navigate to="/login" />}
        />
      </Routes>
    </div>
  );
}

export default App;
